.revenue-header {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}

.revenue-card-div {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
}
.card-div {
  /* width: 300px;
  height: 150px; */
}
.card-text-div {
  display: flex;
  gap: 15px;
}
.card-text-div-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.9rem;
}
.tabs-div {
  display: flex;
  justify-content: center;
  width: 100%;
}
.exports-btn {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}
.table-div {
  margin-top: 10px;
}
.date-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 10px;
}
.date-input-div {
  display: flex;
  gap: 5px;
  width: 100%;
}

.revenue-order-main-div {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.revenue-order-details-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
}
.revenue-order-details-element {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  width: 50%;
}
.revenue-order-addon-h6 {
  font-size: 0.8rem;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .revenue-card-div {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
  }
  .card-div {
    width: 300px;

    /* height: 150px; */
  }
  .card-text-div {
    display: flex;
    gap: 15px;
  }
  .card-text-div-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;
  }
  .revenue-order-details-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }
  .revenue-order-details-element {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-start;
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .revenue-header {
    display: flex;
    gap: 10px;
    justify-content: center;
    width: 100%;
    align-items: center;
  }

  .revenue-card-div {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
  }
  .card-div {
    width: 300px;

    /* height: 150px; */
  }
  .card-text-div-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.6rem;
  }
  .date-input {
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 15px;
    margin-bottom: 0px;
    margin: 0px;
    padding: 0px;
  }
  .date-input-div {
    display: flex;
    flex-direction: column;
    gap: 0px;
    width: 50%;
    margin: 0px;
    padding: 0px;
  }
  .exports-btn {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
  }
  .revenue-order-details-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }
  .revenue-order-details-element {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-start;
    width: 100%;
  }
  .revenue-order-addon-h6 {
    font-size: 0.7rem;
  }
}
